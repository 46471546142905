<template>
  <section class="missingPage">
  </section>
</template>

<script>

export default {
  components: {}
}
</script>

<style lang="sass" scoped>
.missingPage
  min-height: 100vh
  display: flex
  flex-direction: column
  justify-content: space-between
</style>
